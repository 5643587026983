import { createContext, useState, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "../../hooks/useAuth";
import { useAccount } from "../../hooks/useAccount";
import { apiV1 } from "../../lib/axios";

export const TicketContext = createContext({});

export function TicketProvider() {
  const [step, setStep] = useState(1);
  const [ticketLoader, setTicketLoader] = useState(false);
  const [boleto, setBoleto] = useState({});
  const [boletos, setBoletos] = useState([]);
  const [boletosLoader, setBoletosLoader] = useState(false);
  const [emitedTicketCod, setEmitedTicketCod] = useState("");

  const { account } = useAccount();
  const { user } = useAuth();

  const fetchTickets = useCallback(async () => {
    setBoletosLoader(true);
    
    try {
      const response = await apiV1.get("/boleto/list");
      setBoletos(response.data.data.rows);
    } catch (error) {
      console.error("Erro ao buscar boletos:", error);
      toast.error("Não foi possível carregar seus boletos. Tente novamente.");
    } finally {
      setBoletosLoader(false);
    }
  }, []);

  const getTicket = useCallback(async (codBoleto) => {
    setTicketLoader(true);
    
    try {
      const boletoInfo = await getTicketFitbank(codBoleto);
      return boletoInfo;
    } catch (error) {
      console.error("Erro ao obter informações do boleto:", error);
      throw error;
    } finally {
      setTicketLoader(false);
    }
  }, []);

  const getTicketFitbank = useCallback(async (codBoleto) => {
    try {
      const response = await apiV1.get(`/payment/info-cip/fitbank/${codBoleto}`);
      const boletoData = response.data.ticket;
      
      setStep(2);
      setBoleto(boletoData);
      
      return boletoData;
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "Erro ao processar o boleto";
        toast.error(`${errorMessage}`);
      } else {
        toast.error('Erro ao tentar obter informações do boleto. Verifique a conexão e tente novamente.');
      }
      throw error;
    }
  }, []);

  const payTicket = useCallback(async (data) => {
    setTicketLoader(true);
    
    try {
      const paymentBody = {
        barCode: boleto.barcode,
        dueDate: boleto.dueDate || moment().tz('America/Sao_Paulo').toISOString(),
        value: boleto.value,
        receiverName: data.receiverName,
        receiverTaxNumber: data.receiverTaxNumber,
        otp: data.otp
      };
      
      await payTicketFitbank(paymentBody, data);
    } catch (error) {
      console.error("Erro ao pagar boleto:", error);
      toast.error("Falha ao processar o pagamento. Verifique suas informações e tente novamente.");
      throw error;
    } finally {
      setTicketLoader(false);
    }
  }, [boleto]);

  const payTicketFitbank = useCallback(async (body, data) => {
    try {
      const response = await apiV1.post("/payment/fitbank/pay-ticket", body);
      if (!response.data || !response.data.payment) throw new Error("Resposta de pagamento inválida");
      
      setStep(3);
      return response.data.payment;
    } catch (error) {
      console.error("Erro ao processar pagamento Fitbank:", error);
      toast.error("Erro ao processar o pagamento. Por favor, tente novamente.");
      throw error;
    }
  }, []);

  const emitTicketFitbank = useCallback(async (data) => {
    setTicketLoader(true);
    
    try {
      const response = await apiV1.post("/boleto/fitbank/emit", data);
      if (!response.data || !response.data.data) throw new Error("Resposta de emissão de boleto inválida");
      
      setEmitedTicketCod(response.data.data.codigoBarras);
      localStorage.setItem("boletoPDF", response.data.data.boletoBase64);
      setStep(3);
      return response.data.data;
    } catch (error) {
      console.error("Erro ao emitir boleto:", error);
      toast.error("Não foi possível emitir o boleto. Verifique os dados e tente novamente.");
      throw error;
    } finally {
      setTicketLoader(false);
    }
  }, []);

  const generateVoucher = useCallback(async (data) => {
    try {
      const response = await apiV1.post("/voucher/generate", data);
      
      if (!response.data || !response.data.data) throw new Error("Resposta de geração de comprovante inválida");

      localStorage.removeItem("comprovante");
      localStorage.setItem("comprovante", response.data.data.base64);
      return response.data.data;
    } catch (error) {
      console.error("Erro ao gerar comprovante:", error);
      toast.error("Não foi possível gerar o comprovante, mas seu pagamento foi processado.");
      throw error;
    }
  }, []);

  const contextValue = {
    ticketLoader,
    setTicketLoader,
    step,
    setStep,
    boleto,
    boletos,
    boletosLoader,
    emitedTicketCod,
    
    getTicket,
    payTicket,
    fetchTickets,
    emitTicketFitbank,
  };

  return (
    <TicketContext.Provider value={contextValue}>
      <Outlet />
    </TicketContext.Provider>
  );
}