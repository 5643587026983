import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { TbReceipt, TbKey } from 'react-icons/tb';
import { FiShare2 } from 'react-icons/fi';
import { css } from '@emotion/react';
import moment from 'moment-timezone';

import { Input } from '../../../components/Input';
import { Box } from '../../../components/box';
import { PageHeader } from '../../../components/page-header';
import { FilterItemMfaGeneralBoleto } from '../../../components/E-check/style';

import { useTicket } from '../../../hooks/useTicket';
import { useAuth } from '../../../hooks/useAuth';
import { sendTransactionMfa } from '../../../services/mfa.service';
import { getImage } from '../../../services/getAssets';
import { apiV1 } from '../../../lib/axios';

import { formatCnpjCpf } from '../../../utils/formatter';
import { TransactionType } from '../../../services/enums';
import bankList from '../../../services/bankList.json';

import './styles.css';

const loaderOverride = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 3em;
`;

export function PagarBoleto() {
  const [codBoleto, setCodBoleto] = useState('');
  const [codBoletoError, setCodBoletoError] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isVoucherButtonDisabled, setIsVoucherButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const {
    ticketLoader,
    setTicketLoader,
    step,
    boleto,
    getTicket,
    setStep,
    payTicket,
  } = useTicket();
  
  const { user, getProfile } = useAuth();

  const getBankName = (bankCode) => {
    const bank = bankList.find((item) => item.code == bankCode);
    return bank ? bank.name : '';
  };

  const formatCurrency = (value) => {
    return Number(value || 0)
      .toFixed(2)
      .toString()
      .replace('.', ',');
  };

  useEffect(() => {
    if (!user.id) {
      getProfile();
    }
  }, [user, getProfile]);

  useEffect(() => {
    setStep(1);
  }, [setStep]);

  const handleMfaRequest = async () => {
    await sendTransactionMfa();
    setIsDisabled(true);
    setCountdown(40);

    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(interval);
          setIsDisabled(false);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const validateBoleto = (code) => {
    if (!/^[\d.\s]+$/.test(code)) {
      return 'O código do boleto deve conter apenas números.';
    }

    if (code.length < 47) {
      return 'O código do boleto é muito curto. Verifique e tente novamente.';
    }

    return '';
  };

  const validateBoletoResponse = (boletoResult) => {
    if (!boletoResult || !boletoResult.barcode || boletoResult.value <= 0) {
      return 'Boleto inválido. Verifique as informações e tente novamente.';
    }

    if (
      !boletoResult.dueDate ||
      (typeof boletoResult.dueDate === 'string' &&
        boletoResult.dueDate.trim() === '')
    ) {
      return 'Data inválida ou não fornecida. Por favor, preencha a data.';
    }

    return '';
  };

  const handleProceed = async () => {
    setCodBoletoError('');
    setTicketLoader(true);

    try {
      const validationError = validateBoleto(codBoleto);
      if (validationError) {
        setCodBoletoError(validationError);
        return;
      }

      const boletoResult = await getTicket(codBoleto);
      
      const responseError = validateBoletoResponse(boletoResult);
      if (responseError) {
        setCodBoletoError(responseError);
        return;
      }

      await sendTransactionMfa();
    } catch (error) {
      console.error('Erro ao validar o boleto:', error.message);
      setCodBoletoError('Erro ao validar o boleto. Erro: ' + error.message);
    } finally {
      setTicketLoader(false);
    }
  };

  const handleBoletoConfirmation = async (otp) => {
    if (!otp) {
      return toast.error('O código MFA não pode estar vazio.');
    }
    await handleConfirmPayment(otp);
  };

  const generatePaymentData = (otp) => {
    const currentDateTime = moment().tz('America/Sao_Paulo').toISOString();
    const payerBank = `${user.account.bank} - ${getBankName(user.account.bank)}`;

    return {
      dueDate: currentDateTime,
      type: TransactionType.PreDebitBoletoOut,
      receiverName: boleto.beneficiaryName,
      receiverTaxNumber: boleto.taxNumberBeneficiary,
      payerName: user.name,
      payerTaxNumber: user.taxNumber,
      payerBank: payerBank,
      payerBankBranch: user.account.bankBranch || "Não informado",
      payerBankAccount: user.account.bankAccount || "Não informado",
      payerBankAccountDigit: user.account.bankAccountDigit || "Não informado",
      identifier: boleto.identifier,
      value: parseFloat(boleto.value),
      barCode: boleto.barcode,
      url_logo: getImage('logo-preto.png'),
      otp,
      transferDate: currentDateTime,
    };
  };

  const generateVoucher = async (data) => {
    try {
      const response = await apiV1.post('/voucher/generate', data);
      const res = response.data.data;
      localStorage.removeItem('comprovante');
      localStorage.setItem('comprovante', res.base64);
    } catch (error) {
      console.error('Erro ao gerar comprovante:', error);
      throw error;
    }
  };

  const handleConfirmPayment = async (otp) => {
    let transactionId;
    const paymentData = generatePaymentData(otp);

    try {  
      const paymentResponse = await payTicket(paymentData);
      transactionId = paymentResponse.id;
    } catch (err) {
      toast.error('Erro ao tentar realizar o pagamento.');
      console.error("Erro ao pagar:", err);
      return;
    }
  
    try {
      await generateVoucher({...paymentData, transactionId});
    } catch (err) {
      toast.warn('Pagamento realizado, porém não foi possível gerar o comprovante.');
      console.error("Erro ao gerar comprovante:", err);
      localStorage.removeItem('comprovante');
      setIsVoucherButtonDisabled(true);
    }
  };

  const renderStep1 = () => (
    <Box
      title="Pagar Boleto"
      description="Digite o código de barras do boleto que você deseja pagar"
    >
      <div className="step1-content">
        <Input
          type="text"
          label="Boleto bancário"
          icon={<TbReceipt size={18} />}
          value={codBoleto}
          onChange={setCodBoleto}
          error={codBoletoError}
          isRequired
          maxLength={65}
        />

        <div className="step-buttons">
          <NavLink to="/pagina-boleto" className="step-button">
            Cancelar
          </NavLink>
          <button
            className="step-button step-button-red"
            onClick={handleProceed}
          >
            Prosseguir
          </button>
        </div>
      </div>
    </Box>
  );

  const renderStep2 = () => (
    <Box title="Confirmar dados de Boleto">
      <div className="step2-content">
        <div className="step2-content-infos">
          <div className="step2-content-info">
            <div className="info-item">
              Valor à pagar
              <span>R$: {formatCurrency(boleto.value)}</span>
            </div>

            <div className="info-item">
              Código de Boleto
              <span>{boleto.barcode}</span>
            </div>
          </div>

          <div className="step2-content-info">
            <div className="info-item">
              Valor original
              <span>R$: {formatCurrency(boleto.totalValue)}</span>
            </div>

            <div className="info-item">
              Valor do desconto
              <span>R$: {formatCurrency(boleto.discountValue)}</span>
            </div>

            {boleto.interestValue && (
              <div className="info-item">
                Valor do juros
                <span>R$: {formatCurrency(boleto.interestValue)}</span>
              </div>
            )}

            {boleto.fineValue && (
              <div className="info-item">
                Valor da multa
                <span>R$: {formatCurrency(boleto.fineValue)}</span>
              </div>
            )}
          </div>

          <div className="step2-content-info">
            <div className="info-item">
              Instituição de Destino
              <span>
                {boleto.bankCode ? `${boleto.bankCode} -` : ''} {boleto.bankName}
              </span>
            </div>

            <div className="info-item">
              CPF/CNPJ do Favorecido
              <span>
                {boleto.taxNumberBeneficiary
                  ? formatCnpjCpf(boleto.taxNumberBeneficiary)
                  : 'Não informado.'}
              </span>
            </div>
          </div>
        </div>

        <div className="step2-input-container">
          <Input
            type="text"
            label="Código de confirmação"
            placeholder="Insira o código MFA"
            icon={<TbKey size={18} />}
            value={mfaCode}
            onChange={setMfaCode}
            isRequired
          />
        </div>
        
        <FilterItemMfaGeneralBoleto>
          {isDisabled && (
            <p>
              Você pode solicitar um novo
              <br /> código em 0:{countdown} segundos
            </p>
          )}
          <button onClick={handleMfaRequest} disabled={isDisabled}>
            Novo Código
          </button>
        </FilterItemMfaGeneralBoleto>

        <div className="step-buttons step2-buttons">
          <button
            className="step-button"
            onClick={() => {
              setStep(1);
              setCodBoleto('');
            }}
          >
            Voltar
          </button>
          <button
            className="step-button step-button-red"
            onClick={() => handleBoletoConfirmation(mfaCode)}
          >
            Confirmar Pagamento
          </button>
        </div>
      </div>
    </Box>
  );

  const renderStep3 = () => (
    <Box
      icon="circleCheck"
      title="Pagamento de Boleto realizado com sucesso"
    >
      <div className="step3-content">
        <div className="step3-content-infos">
          <div className="step3-content-info">
            <div className="info-item">
              Valor
              <span>R$: {formatCurrency(boleto.value)}</span>
            </div>

            <div className="info-item">
              Código de Boleto
              <span>{boleto.barcode}</span>
            </div>
          </div>

          <div className="step3-content-info">
            <div className="info-item">
              Instituição de Destino
              <span>
                {boleto.bankCode} - {boleto.bankName}
              </span>
            </div>
          </div>
        </div>

        <div className="step-buttons step3-buttons">
          <NavLink
            target="_blank"
            to={isVoucherButtonDisabled ? "#" : "/comprovante"}
            className={`step-button ${isVoucherButtonDisabled ? "disabled-link" : ""}`}
            onClick={(e) => isVoucherButtonDisabled && e.preventDefault()}
          >
            Compartilhar comprovante
            <FiShare2 size={16} />
          </NavLink>
        </div>
      </div>
    </Box>
  );

  const renderLoader = () => (
    <div className="loader-div">
      <BeatLoader
        color={'var(--secondary-color)'}
        loading={true}
        css={loaderOverride}
        size={20}
      />
    </div>
  );

  return (
    <div className="pagar-boleto-container">
      <PageHeader
        title="Pagar boleto"
        pages={[
          { name: 'Boleto', link: '/pagina-boleto' },
          { name: 'Pagar Boleto', link: '' },
        ]}
      />

      {ticketLoader ? renderLoader()
       : 
        <>
          {step === 1 && renderStep1()}
          {step === 2 && renderStep2()}
          {step === 3 && renderStep3()}
        </>
      }
    </div>
  );
}
