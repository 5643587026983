import React from "react";;
import 'jspdf-autotable';
import { useState } from 'react';
import { FaFilePdf, FaFileCsv } from 'react-icons/fa';
import Select from 'react-select';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PopUpDivMain, ButtonExport, ButtonDiv } from "./styles";
import { FilterButton } from "../styles";

export default function (props) {

  const [exportType, setExportType] = useState('');
  const [placeholder, setPlaceholder] = useState('selecione o tipo de exportação');
  const [loading, setLoading] = useState(false);

  function calcularTotalizador(data) {
    return data.reduce((total, item) => {
      const value = parseFloat(item.value);
      return isNaN(value) ? total : total + value;
    }, 0);
  }

  async function Export() {
    if (exportType) {
      if (exportType === 'PDF') {
        generatePDF();
      } else {
        generateCSV();
      }
    } else {
      alert('Selecione o tipo de exportação');
    }
  }

  function formatBrlDate(date) {
    const [data] = date.split(" ");
    const [ano, mes, dia] = data.split("-");
    return `${dia}/${mes}/${ano}`;
  }

  function formatCurrency(value) {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (isNaN(value)) return 'R$ 0,00';
    const formattedValue = Math.abs(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return value < 0 ? `R$ -${formattedValue}` : `R$ ${formattedValue}`;
  }

  function formatEntrys(entry) {
    if (!entry || typeof entry !== 'string' || /undefined|Não Informado/i.test(entry)) {
      return '-';
    }
    return entry;
  }

  async function generateCSV() {
    let header = "Nº da transação, CPF/CNPJ DO DESTINATÁRIO, NOME DO DESTINATÁRIO, BANCO DO DESTINATARIO, AGÊNCIA DO DESTINATARIO, Nº DA CONTA DO DESTINATARIO, TIPO DA TRANSAÇÃO, VALOR DA TRANSAÇÂO, NOME DO REMETENTE, CPF/CNPJ DO REMETENTE, BANCO DO REMETENTE, AGÊNCIA DO REMETENTE, Nº DA CONTA DO REMETENTE, DATA DA TRANSAÇÃO \n";
    setLoading(true);
    const csvBody = props.data.map((entry) => {
      return `${formatEntrys(entry.documentNumber)}, ${formatEntrys(entry.receiverTaxNumber)}, ${formatEntrys(entry.receiverName)}, ${formatEntrys(entry.receiverBank)}, ${formatEntrys(entry.receiverBankBranch)}, ${formatEntrys(`${entry.receiverBankAccount} - ${entry.receiverBankAccountDigit}`)}, ${formatEntrys(entry.description)}, ${formatCurrency(entry.value)}, ${formatEntrys(entry.payerName)}, ${formatEntrys(entry.payerTaxNumber)}, ${formatEntrys(entry.payerBank)}, ${formatEntrys(entry.payerBankBranch)}, ${formatEntrys(`${entry.payerBankAccount} - ${entry.payerBankAccountDigit}`)}, ${formatBrlDate(entry.date)} \n`;
    });
    const csv = header + csvBody.join('');
    const encodedUri = encodeURI(csv);
    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodedUri);
    link.setAttribute('download', 'relatorio.csv');
    document.body.appendChild(link);
    setTimeout(function () {
      setLoading(false);
      link.click();
    }, 1000);
  }

  function generatePDF() {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'A4'
    });
    setLoading(true);
    autoTable(doc, {
      head: [[
        'Nº da transação', 'CPF/CNPJ DO DESTINATÁRIO', 'NOME DO DESTINATÁRIO', 'BANCO DO DESTINATÁRIO',
        'AGÊNCIA DO DESTINATÁRIO', 'Nº DA CONTA DO DESTINATÁRIO', 'TIPO DA TRANSAÇÃO',
        'VALOR DA TRANSAÇÃO', 'NOME DO REMETENTE', 'CPF/CNPJ DO REMETENTE',
        'BANCO DO REMETENTE', 'AGÊNCIA DO REMETENTE', 'Nº DA CONTA DO REMETENTE',
        'DATA DA TRANSAÇÃO'
      ]],
      body: props.data.map((entry) => [
        formatEntrys(entry.documentNumber),
        formatEntrys(entry.receiverTaxNumber),
        formatEntrys(entry.receiverName),
        formatEntrys(entry.receiverBank),
        formatEntrys(entry.receiverBankBranch),
        `${formatEntrys(`${entry.receiverBankAccount} - ${entry.receiverBankAccountDigit}`)}`,
        formatEntrys(entry.description),
        formatCurrency(entry.value),
        formatEntrys(entry.payerName),
        formatEntrys(entry.payerTaxNumber),
        formatEntrys(entry.payerBank),
        formatEntrys(entry.payerBankBranch),
        `${formatEntrys(`${entry.payerBankAccount} - ${entry.payerBankAccountDigit}`)}`,
        formatBrlDate(entry.date)
      ]),
      styles: { fontSize: 6, halign: 'center' },
      margin: { top: 20, bottom: 20 },
      pageBreak: 'auto',
      theme: 'grid'
    });

    const finalY = doc.lastAutoTable.finalY || 20;
    const pageWidth = doc.internal.pageSize.width;
    doc.setFont(undefined, 'bold');
    doc.setFontSize(10);

    const text = `Total Transacionado: R$ ${calcularTotalizador(props.data)}`;
    const textWidth = doc.getTextWidth(text);
    const xPosition = pageWidth - textWidth - 40;
    doc.text(text, xPosition, finalY + 30);

    doc.save('relatorio.pdf');
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  function handleFilterOptions(e) {
    setExportType(e.value);
    setPlaceholder(e.label);
  }

  function closePopup() {
    props.setLoader(false);
    props.setShowPopup(false);
  }

  const options = [
    { value: 'PDF', label: <><FaFilePdf /> Exportar PDF</> },
    { value: 'CVS', label: <><FaFileCsv /> Exportar CSV</> },
  ];


  return (
    <PopUpDivMain className="col-md-4">
      <h4>Selecione exportação</h4>
      <Select className="select placeholder-gray-500"
        onChange={(e) => handleFilterOptions(e)}
        options={options}
        placeholder={placeholder}
      />
      <ButtonDiv>
        {!loading ? <ButtonExport className="col-md-3 btn" onClick={() => Export()}>Exportar</ButtonExport> : <FilterButton disabled>Exportando...</FilterButton>}
        <button className="btn btn-secondary col-md-3" onClick={() => closePopup()}>Fechar</button>
      </ButtonDiv>
    </PopUpDivMain>
  );
}
