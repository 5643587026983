import { Input } from '../../../../components/Input';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  CompanyDataFormContent,
  CompanyDataFormInputs,
  InputContainer,
  InstituicaoInput,
  Select,
  ErrorMessage,
  Div,
  WarnMessage,
  Body,
} from './style';
import { Select as SelectComponent } from '../../../../components/select';
import { Box } from '../../Box';
import Asterisk from '../../../../shared/components/asterisk/asterisk';
import { useNavigate } from 'react-router-dom';
import { useRegister } from '../../../../hooks/useRegister';
import { getCNPJ } from '../../../../services/getCNPJ';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { formatPhone } from '../../../../utils/formatter';
import { toast } from 'react-toastify';
import { getCnaes } from '../../../../utils/cnaesList';
import { FaCircleExclamation } from 'react-icons/fa6';
import ReactModal from 'react-modal';
import { BoxModal } from '../../../../components/boxModal';
import { sendNewAccountMfa } from '../../../../services/mfa.service';

const CompanyDataFormSchema = z.object({
  cnpj: z.string()
  .min(14, 'O CNPJ deve ter 14 dígitos numéricos.')
  .max(18, 'O CNPJ aceita no máximo 18 caracteres (com máscara).'),
  
  companyType: z.enum(['0', '1', '2', '3'], {
    errorMap: () => ({ message: 'Escolha uma das opções.' }),
  }),
  
  fantasyName: z.string()
  .regex(/^[a-zA-Z0-9\u00C0-\u017F.,\s]+$/, 'Nome Fantasia inválido.'),
  
  companyActivity: z.string(),
  monthlyIncome: z.string().min(1, 'Informe o faturamento mensal.'),

  password: z.string()
    .min(8, 'A senha deve ter pelo menos 8 caracteres.')
    .regex(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula.')
    .regex(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula.')
    .regex(/[0-9]/, 'A senha deve conter pelo menos um número.')
    .regex(/[^a-zA-Z0-9]/, 'A senha deve conter pelo menos um caractere especial.'),

    confirmPassword: z
    .string()
    .min(8, 'O campo senha precisa de no mínimo 8 caracteres.'),
});

const companyTypeList = [
  { label: 'Selecione...', value: '', disabled: true },
  { label: 'SA', value: '0' },
  { label: 'LTDA', value: '1' },
  { label: 'MEI', value: '2' },
  { label: 'ME', value: '3' },
];

export const CompanyDataForm = () => {
  const { postCompanyDataPj, companyDataPj } = useRegister();

  const [socialReason, setSocialReason] = useState(
    companyDataPj.socialReason ?? ''
  );
  const [constitutionDate, setConstitutionDate] = useState(
    companyDataPj.constitutionDate ?? ''
  );
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyActivity, setCompanyActivity] = useState('');
  const [monthlyIncome, setMonthlyIncome] = useState('');
  const [cnaes, setCnaes] = useState();
  const [warn, setWarn] = useState(false);

  const [disableCompanyDataInputs, setDisableCompanyDataInputs] = useState({
    socialReason: false,
    constitutionDate: false,
    email: false,
    phone: false,
  });

  const getCnaesList = async () => {
    const cnaeList = await getCnaes();

    setCnaes(
      cnaeList?.data.map((cnae) => ({
        label: cnae.id + ' - ' + cnae.descricao,
        value: cnae.descricao,
      }))
    );
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      cnpj: companyDataPj.cnpj ?? '',
      socialReason: companyDataPj.socialReason ?? '',
      companyType: companyDataPj.companyType ?? '',
      companyActivity: companyDataPj.companyActivity ?? '',
      fantasyName: companyDataPj.fantasyName ?? '',
      constitutionDate: companyDataPj.constitutionDate ?? '',
      monthlyIncome: companyDataPj.monthlyIncome ?? '',
    },
    resolver: zodResolver(CompanyDataFormSchema),
  });

  const handleNext = async (data) => {
    if (!socialReason || !constitutionDate || !email || !phone || !companyActivity || !monthlyIncome) {
      return toast.error('Preencha todos os campos obrigatórios.');
    }
  
    if (unMaskPhoneNumber(phone).length !== 11) {
      return toast.error('Telefone Inválido.');
    }
  
    const body = { ...data, socialReason, constitutionDate, email, phone, companyActivity, monthlyIncome };
 console.log("body ", body);
  
    await sendNewAccountMfa(formatToNumbersOnly(phone));
  
    postCompanyDataPj(body);
  };

  const unMaskPhoneNumber = (phone) => phone.replace(/[^0-9]/g, '');
  
  const formatToNumbersOnly = (input) => input.replace(/\D/g, '');

  const handleBack = () => navigate('/');
  
  const checkCNPJ = async (cnpj) => {
    const unformattedCnpj = cnpj.replace(/\D/g, '');

    if (unformattedCnpj.length === 14) {
      try {
        const response = await getCNPJ(cnpj);

        setSocialReason(response.razao_social);
        setConstitutionDate(response.estabelecimento.data_inicio_atividade);
        setCompanyActivity(
          response?.estabelecimento?.atividade_principal?.descricao.toUpperCase()
        );

        setDisableCompanyDataInputs({
          socialReason: response.razao_social,
          constitutionDate: response.estabelecimento.data_inicio_atividade,
          email: response.estabelecimento.email,
          phone:
            response.estabelecimento.ddd1 + response.estabelecimento.telefone1,
        });

        clearErrors('cnpj');
      } catch (err) {
        setError('cnpj', { type: 'custom', message: 'CNPJ inválido.' });
      }
    }
  };

  const handleMouseEnter = () => setWarn(true);

  const handleMouseLeave = () => {
    setTimeout(() => {
      setWarn(false);
    }, 2000);
  };

  const handleModalEnter = () => setWarn(true);

  const handleModalLeave = () => setWarn(false);

  useEffect(() => {
    getCnaesList();
  }, []);

  return (
      <Box
        handleSubmit={handleSubmit}
        submitEvent={handleNext}
        title="Preencha seus dados"
        description="Complete os campos essenciais para iniciar o processo de abertura de conta empresarial"
        buttonOne="Cancelar"
        buttonTwo="Prosseguir"
        marginLeft="l"
        handleBack={handleBack}
      >
        <CompanyDataFormInputs>
          <CompanyDataFormContent>
            <span>Dados da Empresa</span>

            <InputContainer>
              <Input
                type="mask"
                label="CNPJ"
                placeholder="00.000.000/0001-00"
                mask="99.999.999/9999-99"
                error={errors.cnpj && errors.cnpj.message}
                isRequired
                register={register('cnpj', {
                  required: true,
                  onChange: (e) => checkCNPJ(e.target.value),
                })}
              />

              <Input
                type="text"
                label="Razão Social"
                value={socialReason}
                onChange={setSocialReason}
                isRequired
              />

              <InstituicaoInput>
                <label>
                  Tipo de Empresa <Asterisk />
                </label>
                <SelectComponent
                  register={register('companyType', {
                    required: true,
                  })}
                  error={errors.companyType && errors.companyType.message}
                  options={companyTypeList}
                ></SelectComponent>
              </InstituicaoInput>

              <Input
                type="text"
                label="Nome Fantasia"
                error={errors.fantasyName && errors.fantasyName.message}
                isRequired
                register={register('fantasyName', {
                  required: true,
                })}
              />

              <Input
                type="date"
                label="Data de abertura"
                max={moment(new Date()).format('YYYY-MM-DD')}
                value={constitutionDate}
                onChange={setConstitutionDate}
                isRequired
              />

              <SelectComponent
                label="Atividade da Empresa"
                error={errors.companyActivity && errors.companyActivity.message}
                isRequired
                value={companyActivity}
                register={register(`companyActivity`, {
                  required: true,
                  onChange: (e) => setCompanyActivity(e.target.value),
                })}
                options={cnaes}
              />

              <Input
                type="currency"
                label="Faturamento da empresa"
                error={errors.monthlyIncome && errors.monthlyIncome.message}
                isRequired
                onChange={setMonthlyIncome}
                register={register('monthlyIncome', {
                  required: true,
                })}
              />
            </InputContainer>
          </CompanyDataFormContent>

          <CompanyDataFormContent>
            <span>Dados de Acesso</span>

            <InputContainer>
              <Input
                type="email"
                label="E-mail"
                value={email}
                onChange={setEmail}
                isRequired
              />
              <Input
                type="text"
                label="Celular"
                maxLength={15}
                value={phone}
                onChange={(e) => setPhone(formatPhone(e.replace(/\D/g, '')))}
                isRequired
                onlyNumber
              />
              <Div>
                <FaCircleExclamation
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  size={14}
                />
                {warn && (
                  <ReactModal
                    isOpen={warn}
                    className="change-modal"
                    overlayClassName="overlay-pix-modal"
                    onMouseEnter={handleModalEnter}
                    onMouseLeave={handleModalLeave}
                  >
                    <BoxModal
                      close={() => setWarn(false)}
                      maxWidth={270}
                      title={'aviso'}
                    >
                      <WarnMessage>
                        O número de celular é importante para a segurança da sua
                        conta. Deve-se atentar e digitar seguindo o formato
                        abaixo: (99) 99999-9999
                      </WarnMessage>
                    </BoxModal>
                  </ReactModal>
                )}
              </Div>
              <Input
                type="password"
                label="Senha"
                error={errors.password && errors.password.message}
                isRequired
                register={register('password', {
                  required: true,
                })}
              />
              <Input
                type="password"
                label="Confirme sua senha"
                error={errors.confirmPassword && errors.confirmPassword.message}
                isRequired
                register={register('confirmPassword', {
                  required: true,
                })}
              />
            </InputContainer>
          </CompanyDataFormContent>
        </CompanyDataFormInputs>
      </Box>
  );
};
